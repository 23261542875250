import React from 'react';

import { useTheme } from '@tmap/mmm-style-guide/src/Theming';
import imageUrlBuilder from '@sanity/image-url';

import Head from 'next/head';
import client from '../client';
import { usePageLoaded } from './pageLoadedProvider';

const builder = imageUrlBuilder(client);

/**
 * Simple Examples:
```
// Adaptive Resolution
<ResponsivePicture
    image={image}
    width={1920}
    height={1080}
    xl={2}
    style={{ width: '100%', height: 'auto' }}
/>

// Adaptive Dimensions
<ResponsivePicture
    image={image}
    xs={{ width: 320, height: 1080 }}
    md={{ width: 1440, height: 1080 }}
    xl={{ width: 2560, height: 1080 }}
/>
```
 */
function ResponsivePicture(props) {
  const {
    image,
    alt,
    style = {},
    className,
    onLoad,
    width,
    height,
    xs = 1,
    sm,
    md,
    lg,
    xl,
    children,
    upscale,
    preload,
    ignoreImageParams = false,
    ...mainConfig
  } = props;
  const pageLoaded = usePageLoaded();

  const largest = (xl || lg || md || sm || xs);
  const breakpoints = {
    xl,
    lg,
    md,
    sm,
    xs,
  };
  const upscaleBreakpoints = {
    xs: true,
    sm: true,
    ...upscale,
  };

  const theme = useTheme();

  const build = (scale) => {
    if (!image) return null;
    let built = builder.image(image).format('webp');
    if (typeof scale === 'number') {
      if (width) built = built.width(width * scale);
      if (height) built = built.height(height * scale);
    }
    Object.entries(
      typeof scale === 'number' ? mainConfig : { ...mainConfig, ...scale },
    ).forEach(([key, value]) => {
      built = built[key](value);
    });

    // workaround for https://github.com/sanity-io/image-url/issues/32
    if (ignoreImageParams) {
      built = built.ignoreImageParams();
    }

    return built.url();
  };

  return (
    <>
      {preload && (
        <Head>
          {Object.entries(breakpoints).map(([name, scale]) => {
            if (scale) {
              const href = build(scale);
              return (
                <link
                  rel='preload'
                  as='image'
                  href={href}
                  media={theme.breakpoints.up(name).slice('@media '.length)}
                  key={href}
                />
              );
            }
            return null;
          })}
        </Head>
      )}
      <picture
        style={{ ...style, display: image ? 'contents' : 'none' }}
        className={className}
        onLoad={onLoad}
      >
        {Object.entries(breakpoints).map(([name, scale]) => (
          scale ? (
            <source
              key={`source-${name}`}
              srcSet={upscaleBreakpoints[name] && pageLoaded
                ? `${build(scale)} 1x, ${build(typeof scale === 'number' ? scale * 2 : scale)} 2x`
                : build(scale)}
              media={theme.breakpoints.up(name).slice('@media '.length)}
            />
          ) : null
        ))}
        <img
          style={{ ...style, display: 'block' }}
          className={className}
          src={build(largest)}
          width={width}
          height={height}
          alt={alt || image?.alt}
        />
      </picture>
    </>
  );
}

export default ResponsivePicture;
